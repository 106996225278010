import type { MenuProps } from "antd";
import { Menu } from "antd";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";

const items: MenuProps["items"] = [
  {
    label: "Accueil",
    key: "home",
  },
  {
    label: "Entreprise de couverture",
    key: "couverture",
    children: [
      {
        label: "Couvreur à Chantilly",
        key: "couvreur-chantilly",
      },
      {
        label: "Couvreur Marines",
        key: "couvreur-marines",
      },
      {
        label: "Pose et remplacement de tuile ardoise et zinc",
        key: "pose-et-remplacement-de-tuile-ardoise-et-zinc",
      },
      {
        label: "Pose et réparation de gouttière",
        key: "pose-et-reparation-de-gouttiere",
      },
      {
        label: "Nettoyage et démoussage de toiture",
        key: "nettoyage-et-demoussage-de-toiture",
      },
      {
        label: "Fuite et étanchéité de toiture",
        key: "fuite-et-etancheite-de-toiture",
      },
    ],
  },
  {
    label: "Charpente",
    key: "charpente",
    children: [
      {
        label: "Charpentier",
        key: "charpentier",
      },
      {
        label: "Traitement de charpente",
        key: "traitement-de-charpente",
      },
    ],
  },
  {
    label: "Ravalement de façade",
    key: "ravalement-de-facade",
  },
  {
    label: "Pose de velux",
    key: "pose-de-velux",
  },
  {
    label: "Maçonnerie",
    key: "maconnerie",
  },
  {
    label: "Devis gratuit",
    key: "devis-gratuit",
  },
];

export default function Navbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "home") {
      navigate("/");
    } else {
      navigate(e.key);
    }
  };

  return (
    <div className="navbar">
      <Menu
        onClick={onClick}
        selectedKeys={[pathname === "/" ? "home" : pathname.replace("/", "")]}
        mode="horizontal"
        items={items}
        className="navbar_menu"
      />
    </div>
  );
}
