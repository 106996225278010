import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function PoseRemplacementGouttiere() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "La présence des gouttières est primordiale pour la protection optimale des fondations du bâtiment ainsi que de la toiture. Les gouttières sont, en effet, utiles pour évacuer efficacement les eaux de pluies. Sachez que la sécheresse des fondations prévient l’infiltration d’eau ainsi que l’apparition des moisissures. En bref, les gouttières permettent au toit de ne pas stocker les eaux de pluie, évitant ainsi les problèmes d’étanchéité et fuites de la façade. Dans le cas où les gouttières sont abîmées, la réparation s’impose. A cet effet, il est nécessaire de faire appel à un couvreur zingueur. Notre entreprise met à votre disposition des artisans capables de réaliser au préalable la réparation et la pose de vos gouttières. De plus, nous tenons à respecter toutes les normes de sécurité afin de garantir votre sérénité.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="pose_remplacement_goutiere__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Pose et réparation de gouttière à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image
              src={require("../../images/background/pose-de-gouttiere-1.jpeg")}
            />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/pose-de-gouttiere-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Tout Savoir Sur La Pose Et La Réparation Des Gouttières
              </h2>
              <div className="text_description__text_content">
                <div>
                  Les artisans couvreurs ne sont pas uniquement responsables du
                  côté technique. Ils ont en fait la responsabilité de prodiguer
                  des conseils sur les modèles de gouttières à choisir. Parmi
                  les plus populaires, vous retrouverez des gouttières pendantes
                  ou rampantes en zinc ou en PVC.
                </div>
                <div>
                  Il faut savoir que les gouttières en zinc sont plus
                  résistantes aux intempéries et au gel, tandis que les
                  gouttières en PVC son plus sensibles au changement climatique.
                  La pose de gouttières requiert le suivi de quelques étapes. La
                  première consiste à poser les crochets de fixation des
                  gouttières. Il est à noter qu’une pente de 5 mm par mètre est
                  à respecter. Une fois que cela est fait, il est possible de
                  placer le fond de gouttière suivi des raccords et des
                  dernières sections des gouttières.
                </div>
                <div>
                  La réparation est utile dans le cadre de la présence d’une
                  fuite au niveau des gouttières. Avant tout, il est
                  indispensable de trouver sa source. Les couvreurs zingueurs
                  vont ensuite nettoyer les gouttières avec de l’alcool à brûler
                  avant de les percer. Il faut savoir que la réparation peut se
                  faire sous différentes stratégies : le colmatage qui requiert
                  l’utilisation d’un mastic spécial zinc ou le soudage à l’étain
                  qui nécessite l’usage d’un fer à souder.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
