import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function Charpentier() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Une toiture de bonne qualité est la clé pour bénéficier d’un logement résistant, sain et confortable. Il faut savoir que les couvertures de toits vous protègent des sons et des intempéries. A cet effet, il est essentiel de bien entretenir le toit et de privilégier des travaux de qualité. Pour vous accompagner dans votre projet, vous devez solliciter l’aide d’un charpentier. Notre entreprise œuvre dans le domaine de la charpenterie depuis des années. Notre expérience et notre savoir-faire vous seront très utiles. En effet, nous prodiguons des services inestimables tout en vous accompagnant tout au long de la réalisation de votre charpente.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="charpentier__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Charpentier à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/charpente-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/charpente-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Les Activités Proprement Dites D’un Charpentier
              </h2>
              <div className="text_description__text_content">
                <div>
                  Il faut savoir qu’un charpentier est un artisan qui porte
                  plusieurs casquettes. Il est notamment responsable de la pose
                  de la couverture de toit, mais également de son étanchéité. La
                  qualité des travaux dépend de la capacité des charpentiers à
                  s’adapter à toutes les situations. En effet, ils travaillent à
                  la fois en atelier et sur le terrain.
                </div>
                <div>
                  Le travail sur le terrain inclut en premier lieu l’examen du
                  domicile. Nos charpentiers vont au tout début mesurer les
                  dimensions indispensables pour la pose de la charpente. Cette
                  activité est indispensable pour assurer l’étanchéité et la
                  résistance du toit. De ce fait, il est indispensable d’être
                  minutieux et concentré. Cela permet de prévenir tout type de
                  dégât. Une fois cette première étape faite, il est
                  indispensable de dessiner l’épure de la charpente afin d’avoir
                  une idée précise des travaux à faire. Sachez que cette tâche
                  se fait en atelier. Il en est de même pour la découpe des
                  matériaux indispensables à la pose de la charpente.
                  L’assemblage de ces derniers se fera sur le chantier. Une fois
                  que cela est fait, nos artisans vont procéder à un essai
                  d’assemblage, suivi de la pose des liteaux et de la toiture.
                </div>
                <div>
                  A part ces activités manuelles, nos charpentiers vous
                  conseillent sur le matériau adéquat pour votre projet. Pour ce
                  faire, ils vous donnent des informations nécessaires sur les
                  points forts et points faibles de chaque option.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
