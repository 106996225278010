import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function TraitementCharpente() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Lors de la construction d’une charpente, les propriétaires et les charpentiers discutent du matériau à utiliser. Il y a notamment la charpente en métal et la charpente en bois. Cette dernière est connue pour être sensible face aux changements climatiques et aux intempéries. De ce fait, elle requiert un entretien régulier afin qu’elle ne se détériore pas et puisse durer dans le temps. Il faut donc procéder à un traitement curatif ou préventif. Cela permet de prévenir l’apparition des champignons lignivores ainsi que des insectes. Sachez qu’une charpente neuve doit être examinée au même titre qu’une charpente en bois, bien qu’elle bénéficie d’un traitement de 10 ans. Notre entreprise exerce l’activité de traitement de charpente. Fortes de notre expérience, nous vous assurons que votre charpente reste solide et durable pour garantir la sécurité des habitants dans la maison.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="traitement_charpente__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Traitement de charpente à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image
              src={require("../../images/background/traitement-de-charpente-1.jpeg")}
            />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/traitement-de-charpente-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Zoom Sur Le Traitement De Charpente
              </h2>
              <div className="text_description__text_content">
                <div>
                  Toutes les charpentes doivent être vérifiées à un intervalle
                  régulier. Ensuite, il est important de renouveler les
                  traitements. Dans cette optique, deux méthodes sont mises à
                  disposition des charpentiers : le traitement par pulvérisation
                  et le traitement par injection. Le traitement à appliquer sur
                  votre charpente dépend de son état actuel.
                </div>
                <div>
                  Le traitement par pulvérisation est conçu pour les dégâts
                  mineurs, et surtout pour un traitement préventif. Afin de
                  pouvoir le réaliser, nos charpentiers vont tout d’abord
                  gratter la surface de la charpente avant de pulvériser un
                  produit anti-parasitaire.
                </div>
                <div>
                  Le traitement par injection, est quant à lui idéal pour
                  éliminer les problèmes en profondeur. Ici, on parle de
                  l’origine des champignons et des insectes. Le procédé est
                  simple à faire. Les charpentiers doivent creuser des puits de
                  forage qui se distanceront de 30 cm. Ils déposeront par la
                  suite des cartouches à auto-injections ou des produits
                  anti-parasitaires.
                </div>
                <div>
                  Il est à noter qu’avant de réaliser tous ces travaux, nos
                  charpentiers seront en charge de nettoyer et de sécher au
                  préalable la charpente, car cela a de l’impact sur la réussite
                  du traitement. De plus, nos artisans utilisent des produits de
                  qualité et favorisent la sécurité en portant des combinaisons.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
