import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function FuiteEtancheiteToiture() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Une couverture de toit mal installée ou peu entretenue cause des dégâts très importants et onéreux. Il faut savoir que ces situations provoquent des fuites et des soucis d’étanchéité. C’est pour cette raison qu’un entretien régulier du toit est indispensable. Vous aurez ainsi l’opportunité de vous protéger contre les changements climatiques et l’humidité. Il faut savoir qu’une bonne étanchéité du toit permet de gagner un pourcentage important en chaleur, ce qui n’est pas négligeable. Notre entreprise vous propose de réaliser tous les travaux en relation avec les problèmes d’étanchéité et de fuite de votre toiture. Avec notre savoir-faire et expérience de longue date, nous pourrons identifier les origines des problèmes.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="fuite_etancheite__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Fuite et étanchéité de toiture à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/etancheite-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/etancheite-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Les Travaux À Faire Pour Mettre Un Terme Aux Problèmes De Fuites
                Et D’étanchéité D’un Toit
              </h2>
              <div className="text_description__text_content">
                <div>
                  Le manque d’étanchéité ainsi que de fuites est potentiellement
                  causé par un des facteurs suivants : une erreur lors de la
                  prise de mesures pendant la construction du bâtiment, les
                  changements climatiques, la détérioration naturelle du toit,
                  une mauvaise inclinaison de la pente de la toiture. Nos
                  couvreurs sont donc présents dans le but d’identifier
                  l’origine des fuites ainsi que les conséquences qu’elles ont
                  engendrées. S’ils constatent des écoulements d’eau, des taches
                  d’humidité, un mauvais état de la charpente ou des fissures,
                  ils vous informeront sur l’utilité de réaliser des travaux.
                </div>
                <div>
                  Afin de pouvoir profiter d’une bonne isolation de votre
                  maison, nos artisans vont procéder à une isolation par
                  l’intérieur. Cela permet d’avoir une étanchéité à l’air. Pour
                  ce faire, il est indispensable de réaliser un passage par les
                  combles ainsi que la pose d’une membrane pare-vapeur. Sachez
                  qu’il est également possible de procéder à une isolation par
                  l’extérieur. Elle consiste à poser un isolant entre le
                  revêtement et la charpente. Pour favoriser une étanchéité à
                  l’eau, il est important de poser un écran de sous-toiture.
                </div>
                <div>
                  Comme il a été dit, les couvreurs identifient l’origine des
                  fuites. Si elles proviennent de la détérioration de la
                  couverture de toit, ils seront obligés de la remplacer par de
                  nouvelles tuiles, ardoises ou zinc.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
