import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function Maconnerie() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Il faut dire que la maçonnerie est un pilier dans le domaine du bâtiment. En effet, elle est indispensable lors de la construction ainsi que de la rénovation d’un bâtiment ou d’une maison. Les travaux de maçonnerie doivent donc être faits avec précautions. Pour ce faire, il faut solliciter le service des maçons. Ces derniers ont la capacité d’assurer la robustesse et la fiabilité de la maison toute entière. Avec notre entreprise, vous avez accès aux meilleurs maçons. Nos artisans sont expérimentés dans leur domaine et ont un savoir-faire unique. Quels que soient vos besoins, vous pouvez entièrement faire confiance à notre équipe.",
    "Nos maçons se chargent en premier lieu de réaliser des travaux d’ouverture de mur afin de créer une entrée ou une fenêtre à une pièce. Comme cette activité requiert un permis de construire, notre entreprise vous accompagne dans les marches à suivre.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="maconnerie__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Maçonnerie à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/maconnerie-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/maconnerie-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Zoom Sur Les Travaux De Maçonnerie
              </h2>
              <div className="text_description__text_content">
                <div>
                  La maçonnerie est une activité très large. Elle consiste à
                  réaliser des murages de fenêtres, des travaux d’extension de
                  maison, les gros-œuvres pour la construction d’un bâtiment,
                  ainsi que les montages des cloisons. Forts de leur expérience,
                  nos maçons vous conseillent sur les points législatifs et les
                  normes de sécurité à prendre en compte dans le but d’éviter
                  tout problème à l’avenir, et d’assurer le maximum de confort.
                </div>
                <div>
                  Ils sont également responsables de la construction des
                  fondations, des moulages et coffrages, mais aussi du
                  ferraillage. En bref, ils s’attèlent à la construction d’une
                  maison ou d’un bâtiment neuf, ainsi qu’à sa finition une fois
                  que tous les travaux basiques sont faites. Les maçons
                  s’occupent également de l’extension d’une maison, qui
                  nécessite également de gros travaux.
                </div>
                <div>
                  En termes de construction de cloisons, les maçons s’occupent
                  de les installer pour vous. Mais tout d’abord, ils vous
                  conseillent sur les matériaux à choisir. Les briques sont, par
                  exemple, capable d’offrir une isolation de qualité ainsi
                  qu’une régulation optimale de l’humidité. Les panneaux
                  alvéolaires, sont, quant à eux, faciles à monter et sont moins
                  cher que les autres cloisons. Cependant, l’isolation optimale
                  n’est pas favorisée.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
