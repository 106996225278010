import { Col, Row, Form, Input, Button, Select, Divider } from "antd";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "./styles.scss";
const { TextArea } = Input;
const { Option } = Select;

const options = [
  { value: "Couverture" },
  { value: "Charpente" },
  { value: "Ravalement" },
  { value: "Pose de Velux" },
  { value: "Maçonnerie" },
];

export default function ContactUs() {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="contact_us__container">
      <div className="contact_us__form">
        <Row gutter={30}>
          <Col sm={12} xs={24}>
            <h2 className="contact_us__title">
              Faites Confiance À Des Artisans Chevronés
            </h2>
            <div className="contact_us__description">
              C’est une entreprise sérieuse, compétente et soignée. Couverture
              Anthony Lamberger vous garantit un chantier bien réalisé par des
              artisans très professionnels. Ils sont à votre écoute et se
              déplacent dans le Val d'Oise, la Seine Saint Denis et bien
              d’autres départements franciliens pour réaliser vos travaux de
              couverture. Ils se chargent des poses de gouttières, velux,
              tuiles, ardoises et zincs. Ils s’occupent des travaux de
              maçonnerie, d’étanchéité de toiture et de charpente. Enfin, ils
              réalisent le ravalement de façade.
            </div>
            <Divider
              style={{
                backgroundColor: "rgba(225,225,225, 0.5)",
                height: 1.7,
                margin: "40px 0",
              }}
            />
            <div className="contact_us__informations">
              <h2 className="contact_us__subtitle">
                Vous Avez Des Questions ?
              </h2>
              <Row>
                <Col lg={10} md={24} xs={24}>
                  <div className="contact_us__contact_informations">
                    <div>
                      <BsTelephone className="contact_us__icon_title" />
                    </div>
                    <div className="contact_us__contact_text">
                      <div style={{ marginBottom: 5 }}>03 74 11 43 20</div>
                      <div>06 43 62 90 97</div>
                    </div>
                  </div>
                </Col>
                <Col lg={14} md={24} xs={24}>
                  <div className="contact_us__contact_informations">
                    <div>
                      <FiMail className="contact_us__icon_title" />
                    </div>
                    <div className="contact_us__contact_text">
                      contact@sausercouverturechantilly.fr
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <h2 className="contact_us__title">Demande De Devis Gratuit</h2>
            <Form
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={20}>
                <Col lg={12} md={24} xs={24}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "CE CHAMP DOIT ÊTRE RENSEIGNÉ.",
                      },
                    ]}
                  >
                    <Input placeholder="Nom" className="contact_us__input" />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "CE CHAMP DOIT ÊTRE RENSEIGNÉ.",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" className="contact_us__input" />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "CE CHAMP DOIT ÊTRE RENSEIGNÉ.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Téléphone"
                      className="contact_us__input"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={24} xs={24}>
                  <Form.Item
                    name="object"
                    rules={[
                      {
                        required: true,
                        message: "CE CHAMP DOIT ÊTRE RENSEIGNÉ.",
                      },
                    ]}
                  >
                    <Select defaultValue={"Couverture"}>
                      {options.map((option) => (
                        <Option key={option.value}>{option.value}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="demand"
                    rules={[
                      {
                        required: true,
                        message: "CE CHAMP DOIT ÊTRE RENSEIGNÉ.",
                      },
                    ]}
                  >
                    <TextArea
                      className="contact_us__input"
                      placeholder="Message"
                      autoSize={{
                        minRows: 6,
                        maxRows: 6,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="contact_form__submit_button">
                <Form.Item>
                  <Button className="contact_us__send_button" htmlType="submit">
                    Envoyer votre demande
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}
