import { Carousel, Col, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore

import {
  ContactUs,
  OurRealisations,
  OurServices,
  TextDescription,
} from "../../components";
import { MyCarousel } from "./fields";
import "./styles.scss";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Sauser Couverture est située à Chantilly dans l’Oise. Les artisans de l’entreprise se déplacent dans toute la Picardie pour réaliser des travaux de couverture. Ils posent et remplacent les tuiles, ardoises et zincs. Ils démoussent et nettoient les toitures. Ils se chargent également des travaux de charpente, de maçonnerie et du ravalement de façade. Ils posent les velux et les gouttières. Enfin, ils interviennent lors des problèmes de fuites et d’étanchéité de toiture.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <MyCarousel />
      <div className="home_container">
        <Row gutter={40}>
          <Col sm={14} xs={24}>
            <TextDescription
              title="Entreprise Spécialisée Dans Les Travaux De Couverture À Chantilly, Dans l'Oise (60)"
              texts={texts}
            />
          </Col>
          <Col sm={10} xs={24}>
            <Carousel autoplay>
              <div className="home_carousel_slide_1" />
              <div className="home_carousel_slide_2" />
            </Carousel>
          </Col>
        </Row>
      </div>
      <div className="home_carousel_container">
        <OurServices />
      </div>
      <div className="home_carousel_container">
        <OurRealisations />
      </div>
      <div>
        <ContactUs />
      </div>
    </div>
  );
}
