import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Footer, Header } from "../layout";
import {
  Home,
  CouvreurChantilly,
  CouvreurMarines,
  PoseRemplacementTuile,
  NettoyageDemoussageToiture,
  FuiteEtancheiteToiture,
  Charpentier,
  TraitementCharpente,
  RavalementFacade,
  PoseVelux,
  Maconnerie,
  Devis,
} from "../pages";
import PoseRemplacementGouttiere from "../pages/pose-et-reparation-de-gouttiere/PoseRemplacementGouttiere";
function Navigation() {
  return (
    <Router>
      <Header />
      <main className="navigation_container">
        <Routes>
          <Route path="/home" element={<Navigate replace to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/couvreur-chantilly" element={<CouvreurChantilly />} />
          <Route path="/couvreur-marines" element={<CouvreurMarines />} />
          <Route
            path="/pose-et-remplacement-de-tuile-ardoise-et-zinc"
            element={<PoseRemplacementTuile />}
          />
          <Route
            path="/pose-et-reparation-de-gouttiere"
            element={<PoseRemplacementGouttiere />}
          />
          <Route
            path="/nettoyage-et-demoussage-de-toiture"
            element={<NettoyageDemoussageToiture />}
          />
          <Route
            path="/fuite-et-etancheite-de-toiture"
            element={<FuiteEtancheiteToiture />}
          />
          <Route path="/charpentier" element={<Charpentier />} />
          <Route
            path="/traitement-de-charpente"
            element={<TraitementCharpente />}
          />
          <Route path="/ravalement-de-facade" element={<RavalementFacade />} />
          <Route path="/pose-de-velux" element={<PoseVelux />} />
          <Route path="/maconnerie" element={<Maconnerie />} />
          <Route path="/devis-gratuit" element={<Devis />} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default Navigation;
