import { Button, Form, Input } from "antd";
import "./styles.scss";
const { TextArea } = Input;

export default function ContactForm() {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="contact_form__container">
      <div className="contact_form__header">
        <div className="contact_form__header_text">Demande de devis</div>
        <div className="contact_form__header_text">
          Remplissez le formulaire ci-dessous
        </div>
      </div>
      <div className="contact_form__content">
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Votre nom"
            name="name"
            rules={[
              { required: true, message: "Veuillez renseigner votre nom!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Votre addresse mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre adresse mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Votre numéro de téléphone"
            name="phone"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre numéro de tééphone!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Objet"
            name="object"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner l'objet de votre demande!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Votre demande"
            name="demand"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre demande!",
              },
            ]}
          >
            <TextArea
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
            />
          </Form.Item>
          <div className="contact_form__submit_button">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Envoyer votre demande
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}
