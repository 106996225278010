import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import "./styles.scss";

export default function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__hover">
        <div className="footer__content">
          <Row gutter={20}>
            <Col sm={8} xs={24}>
              <div style={{ marginBottom: 30 }}>
                <Link to="/">
                  <img
                    style={{ width: "100%" }}
                    src={require("../../images/logo/artisan2.png")}
                    alt="Sauser Couverture"
                  />
                </Link>
              </div>
              <div className="footer__description_text">
                Située à Chantilly, dans l’Oise, Sauser Couverture est une
                entreprise de couverture qui réalise de nombreux travaux. En
                effet, elle se charge des soucis de fuites et d’étanchéité de
                toiture ainsi que des travaux de maçonnerie. Les artisans de
                l’entreprise s’occupent de la pose de velux, gouttières, tuiles,
                ardoises et zincs. Ils traitent les charpentes et démoussent les
                toitures. Enfin, ils procèdent au ravalement de façade.
                L’entreprise intervient dans tous les départements de l'Oise
                (60), la Somme (80) et l'Aisne (02).
              </div>
            </Col>
            <Col sm={8} xs={24}>
              <h2 className="footer__title">Nos services</h2>
              <div className="footer__divider" />
              <div className="footer__link_container">
                <Row gutter={10}>
                  <Col span={12}>
                    <Link className="footer__link" to="couvreur-chantilly">
                      Couvreur Chantilly
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="charpentier">
                      Charpentier
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="couvreur-marines">
                      Couvreur Marines
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="traitement-de-charpente">
                      Traitement de charpente
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link
                      className="footer__link"
                      to="pose-et-remplacement-de-tuile-ardoise-et-zinc"
                    >
                      Pose et remplacement de tuile ardoise et zinc
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="ravalement-de-facade">
                      Ravalement de facade
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link
                      className="footer__link"
                      to="pose-et-reparation-de-gouttiere"
                    >
                      Pose et reparation de gouttiere
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="pose-de-velux">
                      Pose de velux
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link
                      className="footer__link"
                      to="nettoyage-et-demoussage-de-toiture"
                    >
                      Nettoyage et demoussage de toiture
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="maconnerie">
                      Maconnerie
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link
                      className="footer__link"
                      to="fuite-et-etancheite-de-toiture"
                    >
                      Fuite et etancheite de toiture
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link className="footer__link" to="devis-gratuit">
                      Devis gratuit
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={8} xs={24}>
              <div className="footer__bottom_text_container">
                <h2 className="footer__title">Zones D'intervention</h2>
                <div className="footer__divider" />
                <div className="footer__bottom_text">
                  COUVREUR CHANTILLY, COUVREUR MARINES, COUVREUR CREIL, COUVREUR
                  NOGENT SUR OISE, COUVREUR MONTATAIRE , COUVREUR BEAUVAIS,
                  COUVREUR COMPIÈGNE, COUVREUR VILLERS-SAINT-PAUL, COUVREUR
                  CLERMONT, COUVREUR LAIGNEVILLE, COUVREUR SENLIS, COUVREUR
                  GOUVIEUX, COUVREUR LIANCOURT, COUVREUR RANTIGNY, COUVREUR
                  PLAILLY, COUVREUR AMIENS, COUVREUR SAINT-QUENTIN, COUVREUR
                  NOYON, COUVREUR MOUY, COUVREUR MÉRU, COUVREUR CHAMBLY,
                  COUVREUR PIERREFONDS, COUVREUR PONT-SAINTE-MAXENCE, COUVREUR
                  ABBEVILLE, COUVREUR ABLAINCOURT-PRESSOIR, COUVREUR SOISSONS,
                  COUVREUR LAON, COUVREUR CHAUNY, COUVREUR ACHEUX-EN-AMIÉNOIS,
                  COUVREUR DANS L'OISE (60), COUVREUR DANS LA SOMME (80),
                  COUVREUR DANS L'AISNE (02).
                </div>
              </div>
              {/*<h2 className="footer__title">Carte</h2>
              <div className="footer__divider" />*/}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
