import { Carousel } from "antd";
import { TitleBar } from "../../../../components";
import "./styles.scss";

export default function MyCarousel() {
  return (
    <Carousel autoplay>
      <div className="carousel__first_slide">
        <div className="carousel__slide_over" />
        <div className="carousel__content_slide">
          <TitleBar />
          <h1 className="carousel__main_title">
            Spécialiste en couverture à Chantilly
          </h1>
          <div className="carousel__text">
            Vous souhaitez réaliser des travaux de couverture dans l’Oise ?
            N’hésitez pas à faire appel à Sauser Couverture pour réaliser ces
            travaux.
          </div>
          <div className="carousel__call_button_container">
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:03 74 11 43 20"
              >
                03 74 11 43 20
              </a>
            </div>
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:06 43 62 90 97"
              >
                06 43 62 90 97
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel__second_slide">
        <div className="carousel__content_slide">
          <TitleBar />
          <h1 className="carousel__main_title">
            Expert en ravalement de façade
          </h1>
          <div className="carousel__text">
            Si vous rêvez d’un ravalement de façade soigné et impeccable, faites
            appel à Sauser Couverture pour terminer le chantier.
          </div>
          <div className="carousel__call_button_container">
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:03 74 11 43 20"
              >
                03 74 11 43 20
              </a>
            </div>
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:06 43 62 90 97"
              >
                06 43 62 90 97
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel__third_slide">
        <div className="carousel__content_slide">
          <TitleBar />
          <h1 className="carousel__main_title">
            Artisan maçon à votre service
          </h1>
          <div className="carousel__text">
            Vous êtes à la recherche d’artisans compétents et sérieux pour
            réaliser des travaux de maçonnerie dans le Val d’Oise ? Sauser
            Couverture est celle qui vous faut.
          </div>
          <div className="carousel__call_button_container">
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:03 74 11 43 20"
              >
                03 74 11 43 20
              </a>
            </div>
            <div className="carousel__call_button">
              <a
                className="carousel__call_button_text"
                href="tel:06 43 62 90 97"
              >
                06 43 62 90 97
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="carousel__second_slide"></div>
      <div className="carousel__third_slide"></div>
  <div className="carousel__fourth_slide"></div>*/}
    </Carousel>
  );
}
