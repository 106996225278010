import "./App.css";
import Navigation from "./routes/Navigation";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#fb5a2b",
        },
      }}
    >
      <Navigation />
    </ConfigProvider>
  );
}

export default App;
