import { Button, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import { BsPinMapFill, BsPhone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { AiOutlineFieldTime } from "react-icons/ai";
// @ts-ignore
import Fade from "react-reveal/Fade";
import "./styles.scss";
const { TextArea } = Input;

export default function Devis() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Fade>
        <div className="contact__image"></div>
        <div className="contact__container">
          <Row gutter={20}>
            <Col span={12}>
              <h2 className="text_description__main_title">
                Demandez un devis gratuit
              </h2>
              <Form
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      label="Votre nom"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner votre nom!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Votre addresse mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner votre adresse mail!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Votre numéro de téléphone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message:
                            "Veuillez renseigner votre numéro de tééphone!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Objet"
                      name="object"
                      rules={[
                        {
                          required: true,
                          message:
                            "Veuillez renseigner l'objet de votre demande!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Votre demande"
                      name="demand"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner votre demande!",
                        },
                      ]}
                    >
                      <TextArea
                        autoSize={{
                          minRows: 6,
                          maxRows: 6,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <div className="contact_form__submit_button">
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Envoyer votre demande
                      </Button>
                    </Form.Item>
                  </div>
                </Row>
              </Form>
            </Col>
            <Col span={12}>
              <div className="contact__informations_main_container">
                <Row gutter={[30, 30]}>
                  <Col span={12}>
                    <div className="contact__informations_container">
                      <div className="contact__informations_title_container">
                        <div className="contact__icons_container">
                          <BsPhone className="contact__icons" />
                        </div>
                        <div>Téléphone</div>
                      </div>
                      <div className="contact__divider" />
                      <div>
                        <div>03 74 11 43 20</div>
                        <div>06 43 62 90 97</div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="contact__informations_container">
                      <div className="contact__informations_title_container">
                        <div className="contact__icons_container">
                          <FiMail className="contact__icons" />
                        </div>
                        <div>E-Mail</div>
                      </div>
                      <div className="contact__divider" />
                      <div>
                        <div>contact@sausercouverturechantilly.fr</div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="contact__informations_container">
                      <div className="contact__informations_title_container">
                        <div className="contact__icons_container">
                          <BsPinMapFill className="contact__icons" />
                        </div>
                        <div>Adresse</div>
                      </div>
                      <div className="contact__divider" />
                      <div>
                        <div>9 rue des Otages</div>
                        <div>60500 Chantilly</div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="contact__informations_container">
                      <div className="contact__informations_title_container">
                        <div className="contact__icons_container">
                          <AiOutlineFieldTime className="contact__icons" />
                        </div>
                        <div>Horaires</div>
                      </div>
                      <div className="contact__divider" />
                      <div>
                        <div>Lundi - Samedi : 8H00 - 17H00</div>
                        <div>Dimanche - Fermé</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
