import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function NettoyageDemoussageToiture() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Un entretien régulier de la toiture de votre maison vous permet de profiter de sa pérennité et de sa résistance. Pour ce faire, il est primordial d’entreprendre un nettoyage et un démoussage de la couverture de toit. Ces activités sont à privilégier durant l’automne et le printemps, car ce sont les périodes idéales pour éliminer définitivement les mousses et les lichens qui provoquent la dégradation de la qualité du toit, ainsi que les fuites d’eau. Sachez que notre entreprise se spécialise dans le secteur de la toiture. Nos couvreurs passionnés et expérimentés ont la capacité de réaliser toutes ces tâches tout en valorisant la sécurité et l’utilisation des produits de haute-qualité.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="nettoyage_demoussage__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Nettoyage et démoussage de toiture à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/demoussage-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/demoussage-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                En Quoi Consistent Le Nettoyage Et Le Démoussage De Toiture ?
              </h2>
              <div className="text_description__text_content">
                <div>
                  La sécurité des clients et des membres de l’équipe de notre
                  entreprise est indispensable. A cet effet, nos couvreurs sont
                  les seuls autorisés à réaliser les travaux. Pour ce faire,
                  nous utilisons des matériels adéquats pour les travaux en
                  hauteur. De plus, les artisans ont recours aux chaussures
                  antidérapantes pour prévenir tous types d’accidents dus à
                  l’humidité. Il faut savoir qu’il existe deux types de
                  nettoyage de couverture de toit : le nettoyage au jet d’eau
                  haute pression, et le nettoyage à la main.
                </div>
                <div>
                  Le nettoyage au jet d’eau haute pression se caractérise par sa
                  rapidité et, par conséquent son économie de temps. Avec la
                  pression de la machine, tous les végétaux présents sur le toit
                  s’enlèveront rapidement. Cette méthode est néanmoins sensible,
                  étant donné qu’il faut préserver l’état des ardoises et
                  tuiles. En effet, ils sont très fragiles.
                </div>
                <div>
                  Le nettoyage à la main est très simple. Il suffit de frotter
                  les tuiles à l’aide d’une brosse dure. Cela permet d’éliminer
                  les mousses et les lichens d’une manière efficace, bien
                  qu’elle soit plus longue à faire.
                </div>
                <div>
                  Le développement des végétaux est naturel. Afin de prévenir
                  celui-ci au niveau du toit, le démoussage est nécessaire. Il
                  faut donc pulvériser une solution hydrofuge pour éviter les
                  soucis d’humidité. Nos couvreurs procéderont ensuite à
                  l’installation des fils de cuivre pour que les mousses ne se
                  créent pas.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
