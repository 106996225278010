export const ourServicesList = [
  [
    {
      image: require("../../images/background/couvreur-small-60.jpeg"),
      title: "Couvreur à Chantilly",
      description:
        "Les artisans couvreurs de l'entreprise interviennent dans l'Oise et dans d'autres départements le l'Aisne et de la Somme pour réaliser vos travaux.",
      link: "/couvreur-chantilly",
    },
    {
      image: require("../../images/background/couvreur-2-small-60.jpeg"),
      title: "Couvreur Marines",
      description:
        "Sauser Couveture Chantilly réalisent tous vos travaux de couverture soigneusement afin que vous ne soyez pas déçu.",
      link: "/couvreur-marines",
    },
    {
      image: require("../../images/background/pose-de-tuile-ardoise-zinc-small-60.jpeg"),
      title: "Pose et remplacement de tuile ardoise et zinc",
      description:
        "Notre entreprise intervient rapidement dans la pose et le remplacement de tuiles, ardoises et zincs.",
      link: "/pose-et-remplacement-de-tuile-ardoise-et-zinc",
    },
  ],
  [
    {
      image: require("../../images/background/demoussage-small-60.jpeg"),
      title: "Pose et réparation de gouttière",
      description:
        "Les artisans de l'entreprise posent et réparent les gouttières pour éviter les soucis d'évacuation d'eau.",
      link: "/pose-et-reparation-de-gouttiere",
    },
    {
      image: require("../../images/background/etancheite-small-60.jpeg"),
      title: "Nettoyage et démoussage de toiture",
      description:
        "Nous nettoyons et démoussons les toitures afin qu'elles soient toujours propres et qu'il n'y ait plus de mousse.",
      link: "/nettoyage-et-demoussage-de-toiture",
    },
    {
      image: require("../../images/background/charpente-small-60.jpeg"),
      title: "Fuite et étanchéité de toiture",
      description:
        "Notre entreprise se charge de déceler et de réparer les fuites et les problèmes d'étanchéité de votre toiture.",
      link: "/fuite-et-etancheite-de-toiture",
    },
  ],
  [
    {
      image: require("../../images/background/traitement-de-charpente-small-60.jpeg"),
      title: "Charpentier",
      description:
        "Les artisans de l'entreprise se chargent des travaux de charpente. En effet, ils les posent et les réparent.",
      link: "/charpentier",
    },
    {
      image: require("../../images/background/ravalement-de-facade-small-60.png"),
      title: "Traitement de charpente",
      description:
        "Sauser Couveture Chantilly traite les charpentes pour qu'elles soient saines et non infestées de termites ou de champignons.",
      link: "/traitement-de-charpente",
    },
    {
      image: require("../../images/background/pose-de-velux-small-60.jpeg"),
      title: "Ravalement de façade",
      description:
        "Le ravalement de façade de votre maison est réalisé par les artisans compétents de Couverture Anthony Lamberger.",
      link: "/ravalement-de-facade",
    },
  ],
];
