import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function RavalementFacade() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "La résistance d’une maison et d’un bâtiment vient de l’état de la façade. Il faut savoir que si cette dernière est en mauvais état, le ravalement de façade s’avère nécessaire. Ce type de travaux est connu pour son coût élevé. D’ailleurs, c’est ce que les investisseurs et les propriétaires redoutent le plus. Le ravalement de façade est un ensemble d’activités qui consiste à réparer l’ensemble de la façade, incluant les travaux au niveau du mur extérieur ainsi que les accessoires apparents. Cette activité permet de donner une seconde vie et une résistance à la maison. Notre entreprise est souvent sollicitée pour ce type de travaux. Avec nos artisans, nous vous assurons une qualité de travail irréprochable afin de vous faire profiter d’une belle maison sécurisée.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="ravalement_facade__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Ravalement de façade à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image
              src={require("../../images/background/ravalement-de-facade-1.jpeg")}
            />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/ravalement-de-facade-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Les Activités À Faire Dans Un Ravalement De Façade
              </h2>
              <div className="text_description__text_content">
                <div>
                  La réalisation d’un ravalement de façade est indispensable
                  dans le cas où il y a un décollement de l’enduit, une
                  détérioration de la peinture, la présence d’une fissure ou
                  l’altération d’un joint. Cela est nécessaire si vous ne voulez
                  pas être témoin de dégâts plus importants.
                </div>
                <div>
                  Cependant, avant de débuter les travaux, nos experts vont
                  déterminer la nécessité réelle de travaux importants. A cet
                  effet, ils feront un diagnostic sur l’aspect de l’enduit, les
                  potentielles fuites, l’étanchéité et les fissures. S’il y a
                  des champignons et des fuites, les travaux sont obligatoires.
                </div>
                <div>
                  Les activités de ravalement de façade incluent la rénovation
                  et le nettoyage de la façade. A cet effet, il y a notamment
                  des travaux de sablage et de ponçage. Dans le cadre d’une
                  rénovation, on parle de reboucher les fissures et de remplacer
                  les joints qui sont abîmés.
                </div>
                <div>
                  Une pose d’enduit est adéquate pour assurer la protection de
                  la façade contre les intempéries. Un traitement peut être
                  également fait au niveau du mur.
                </div>
                <div>
                  Le respect des normes de sécurité est indispensable dans un
                  ravalement de façade étant donné que les artisans réalisent
                  des travaux en hauteur. Pour vous rassurer, notre entreprise
                  dispose des matériels de sécurité nécessaire.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
