import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function CouvreurChantilly() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "La toiture est un élément fondamental dans un habitat. Elle garantit en effet la protection des personnes ainsi que la pérennité de la maison. Dans le cadre de pose de couverture de toit, de sa réparation ou rénovation, on fait appel à des couvreurs. Les couvreurs sont des artisans qui s’occupent principalement des travaux de toiture, ainsi que de l’étanchéité et l’isolation d’une maison. Notre entreprise offre son expertise aux personnes dans le besoin. Fortes de notre expérience, nous vous accompagnons durant toute la concrétisation de votre projet. Nos couvreurs garantissent des travaux de qualité et prodigueront en même temps des conseils qui vous seront bénéfiques.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="couvreur__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Couvreur à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/couvreur-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/couvreur-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Les Missions D’un Artisan Couvreur
              </h2>
              <div className="text_description__text_content">
                <div>
                  Un artisan couvreur est avant tout un technicien polyvalent.
                  Il est notamment en charge de conserver l’état de votre maison
                  et de vous protéger contre les agressions extérieures. Les
                  activités qu’il réalise sont donc importantes. Vous
                  retrouverez :
                </div>
                <ul>
                  <li>
                    la pose des tuiles, ardoise et zinc, lors de l’installation
                    de votre couverture de toit.
                  </li>
                  <li>
                    la réparation et la rénovation en cas d’usure causée par le
                    temps ou les intempéries. A cet effet, nos couvreurs en
                    profitent pour vérifier l’étanchéité du toit ainsi que de la
                    façade. Ils pourront ensuite déterminer l’état actuel du
                    bâtiment et proposer des solutions adéquates s’il y a des
                    problèmes.
                  </li>

                  <li>
                    l’installation des dispositifs d’évacuation des eaux de
                    pluies, c’est-à-dire les gouttières. Il faut savoir que ces
                    dernières sont indispensables pour assurer l’étanchéité
                    nécessaire.
                  </li>
                  <li>
                    l’entretien de la couverture de toit. Cette activité inclut
                    le nettoyage, le démoussage, mais aussi le bardage.
                  </li>
                  <li>
                    la pose des accessoires indispensables à l’isolation
                    thermique et sonore durant la mise en place des combles. De
                    plus, nos couvreurs sont également aptes à poser des
                    fenêtres de toit pour faire des combles une pièce à part
                    entière.
                  </li>
                  <li>
                    la prise en charge de la charpente. Ici, ils sont
                    responsables de sa rénovation et de son entretien régulier.
                  </li>
                </ul>
                <div>
                  Il est important de prendre conscience que la toiture doit
                  être correctement posée et bien entretenue pour éviter des
                  dégâts importants au niveau de la maison.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
