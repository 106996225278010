export const ourRealisationsList = [
  {
    image: require("../../images/realisations2/realisation-1.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-2.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-3.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-4.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-5.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-6.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-7.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-8.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-9.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-10.jpeg"),
  },
  {
    image: require("../../images/realisations2/realisation-11.png"),
  },
  {
    image: require("../../images/realisations2/realisation-12.jpeg"),
  },
];
