import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function PoseRemplacementTuile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Il faut savoir que le charme d’une maison provient de son aspect extérieur. C’est pour cela qu’il faut prendre en compte la toiture. Les matériaux, la couleur, les pentes d’une couverture de toit doivent être en harmonie avec l’environnement et les bâtiments voisins. Il est donc nécessaire de bien choisir les matériaux du toit. Vous trouverez notamment la tuile, l’ardoise et le zinc. Notre entreprise met à votre disposition d’exceptionnels artisans. Nos couvreurs sont experts dans leur domaine et vous assurent une qualité de toiture incomparable. De plus, ils vous accompagnent du début jusqu’à la fin de votre projet.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="pose_remplacement_tuile__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Pose et remplacement de tuile ardoise et zinc à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image
              src={require("../../images/background/pose-de-tuile-ardoise-zinc-1.jpeg")}
            />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/pose-de-tuile-ardoise-zinc-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Zoom Sur La Pose Et Le Remplacement De Tuile, Ardoise Et Zinc
              </h2>
              <div className="text_description__text_content">
                <div>
                  Avant de procéder à l’installation de la couverture de toit,
                  il est indispensable d’évaluer les restrictions et les
                  exigences de votre commune par rapport au style du toit ainsi
                  que du matériau à privilégier.
                </div>
                <div>
                  La tuile est la plus utilisée dans le cadre d’une pose et
                  d’une rénovation de couverture de toit. Elle est très robuste
                  et facile à installer. Il faut quand même prendre en compte
                  qu’elle est idéale pour les maisons dans les régions
                  méditerranéennes.
                </div>
                <div>
                  Il faut savoir que la toiture en zinc est légère, moderne et
                  durable. En effet, sa durée de vie peut aller jusqu’à 50 ans.
                  Le zinc est très écologique étant donné qu’il est recyclable.
                  Cependant, sa pose est plus complexe, et il faut dire que nos
                  experts sont parfaitement aptes à la réaliser correctement.
                </div>
                <div>
                  La toiture en ardoise est quant à elle résistante face aux
                  intempéries et à l’humidité. Elle est à la fois élégante et
                  robuste avec sa durée de vie de plus de 100 ans. La pose de
                  toiture en ardoise est conseillée pour des régions pluvieuses
                  et humides.
                </div>
                <div>
                  La détérioration d’une couverture de toit peut être due au
                  temps, aux changements climatiques trop intenses, à des
                  accidents. Pour réaliser le remplacement des tuiles, ardoises
                  et zinc, il faut tout simplement enlever l’ensemble de la
                  couverture de toit, puis de réaliser une nouvelle pose de
                  toiture.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
