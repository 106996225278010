import TitleBarPrimary from "../title-barre-primary/TitleBarPrimary";
import "./styles.scss";

export default function TextDescription(props: any) {
  const { title, texts } = props;
  return (
    <div className="text_description__container">
      <TitleBarPrimary />
      <h2 className="text_description__main_title">{title}</h2>
      <div className="text_description__divider" />
      <div className="text_description__text_container">
        {texts.map((text: string, i: number) => (
          <div className="text_description__text_content" key={i}>
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}
