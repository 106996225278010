import { Button, Card, Carousel, Col, Row } from "antd";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import TitleBarPrimary from "../title-barre-primary/TitleBarPrimary";
import { ourServicesList } from "./our-services";
import "./styles.scss";

export default function OurServices() {
  const carousel = useRef<any>();

  const handleNext = () => carousel.current.next();

  const handlePrev = () => carousel.current.prev();
  return (
    <div className="our_services__container">
      <TitleBarPrimary />
      <div className="our_services__main_title_container">
        <h2 className="text_description__main_title">Nos Services</h2>
        <div>
          <Button onClick={handlePrev} style={{ marginRight: 10 }}>
            <LeftOutlined />
          </Button>
          <Button onClick={handleNext}>
            <RightOutlined />
          </Button>
        </div>
      </div>
      <div className="our_services__carousel">
        <Carousel autoplay ref={carousel}>
          {ourServicesList.map((service, i) => (
            <div key={i}>
              <Row gutter={20}>
                {service.map((item, j) => (
                  <Col
                    key={j}
                    span={8}
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Link to={item.link}>
                      <Card
                        hoverable
                        className="carCard"
                        style={{ height: "100%" }}
                        cover={
                          <img
                            className="carCard__image"
                            alt="Couvreur Chantilly"
                            src={item.image}
                          />
                        }
                      >
                        <div className="our_services__item_description_container">
                          <h2 className="our_services__item_title">
                            {item.title}
                          </h2>
                          <div className="our_services__item_description">
                            {item.description}
                          </div>
                          <Button className="our_services__read_more">
                            Lire la suite
                          </Button>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="our_services__carousel_small">
        <Carousel autoplay ref={carousel}>
          {ourServicesList.map((service) =>
            service.map((item) => (
              <Link to={item.link}>
                <Card
                  hoverable
                  className="carCard"
                  style={{ height: "100%" }}
                  cover={
                    <img
                      className="carCard__image"
                      alt="Couvreur Chantilly"
                      src={item.image}
                    />
                  }
                >
                  <div className="our_services__item_description_container">
                    <h2 className="our_services__item_title">{item.title}</h2>
                    <div className="our_services__item_description">
                      {item.description}
                    </div>
                    <Button className="our_services__read_more">
                      Lire la suite
                    </Button>
                  </div>
                </Card>
              </Link>
            ))
          )}
        </Carousel>
      </div>
    </div>
  );
}
