import { Navbar } from "./fields";
import { BsPinMapFill, BsTelephone, BsPhone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "./styles.scss";
import { Drawer } from "antd";
import { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import NavbarMobile from "./fields/navbar-mobile/NavbarMobile";

export default function Header() {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <header className="header">
      <Drawer
        title={
          <div>
            <img
              src={require("../../images/logo/artisan.png")}
              style={{ width: "60%" }}
              alt="Sauser Couverture"
            />
            <div className="header__drawer_button_container">
              <MenuFoldOutlined
                onClick={onClose}
                className="header__drawer_button"
              />
            </div>
          </div>
        }
        placement="right"
        open={open}
        closable={false}
      >
        <NavbarMobile onClose={onClose} />
      </Drawer>
      <div className="header__drawer_button_container">
        <MenuUnfoldOutlined
          onClick={showDrawer}
          className="header__drawer_button"
        />
      </div>
      <div className="header__top_bar">
        <div className="header__contact_us">
          <FiMail />
          <div className="ml10">Demande de devis gratuit</div>
        </div>
        <div className="header__phone_container">
          <div className="header__phone_first">
            <BsTelephone />
            <div className="ml10">03 74 11 43 20</div>
          </div>
          <div className="header__phone_second">
            <BsPhone />
            <div className="ml10">06 43 62 90 97</div>
          </div>
        </div>
      </div>
      <div className="header__bottom_bar">
        <img
          className="header__logo"
          src={require("../../images/logo/artisan2.png")}
          alt="Sauser Couverture"
        />
        <div className="header__middle_bar">
          <div className="displayFlex">
            <div>
              <FiMail className="header__icon" />
            </div>
            <div className="ml10">
              <div className="header__icon_title">E-Mail</div>
              <div className="header__icon_value">
                contact@sausercouverturechantilly.fr
              </div>
            </div>
          </div>
          <div className="displayFlex">
            <div>
              <BsTelephone className="header__icon" />
            </div>
            <div className="ml10">
              <div className="header__icon_title">Téléphone</div>
              <div className="header__icon_value">03 74 11 43 20</div>
              <div className="header__icon_value">06 43 62 90 97</div>
            </div>
          </div>
          <div className="displayFlex">
            <div>
              <BsPinMapFill className="header__icon" />
            </div>
            <div className="ml10">
              <div className="header__icon_title">Addresse</div>
              <div className="header__icon_value">9 rue des Otages</div>
              <div className="header__icon_value">60500 Chantilly</div>
            </div>
          </div>
        </div>
      </div>
      <div className="header__navbar_container">
        <Navbar />
      </div>
    </header>
  );
}
