import TitleBarPrimary from "../title-barre-primary/TitleBarPrimary";
import { Col, Image, Row } from "antd";
import { ourRealisationsList } from "./our-realisations-list";

export default function OurRealisations() {
  return (
    <div className="our_services__container">
      <TitleBarPrimary />
      <h2 className="text_description__main_title">Nos Réalisations</h2>
      <Row gutter={[20, 20]}>
        {ourRealisationsList.map((item, i) => (
          <Col key={i} sm={8} xs={24}>
            <Image src={item.image} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
