import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function PoseVelux() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "Afin de donner une vie aux combles et de créer une pièce totalement indépendante et fonctionnelle, il est indispensable de réaliser un aménagement de combles. A cet effet, vous devez privilégier l’expertise d’un professionnel dans le secteur du bâtiment, car vous devez penser à faire une pose de velux, communément appelé fenêtre de toit. Ce dernier vous permet d’apporter de l’aération et de la luminosité aux combles, afin que vous puissiez vous sentir bien. Notre entreprise propose de réaliser la pose de votre velux. Nous avons à notre actif des artisans habiles dans ce domaine, et qui vous garantissent une qualité de travaux inégalable.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="pose_velux__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Pose de velux à Chantilly dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image
              src={require("../../images/background/pose-de-velux-1.jpeg")}
            />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/pose-de-velux-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                Tout Sur L’installation Du Velux
              </h2>
              <div className="text_description__text_content">
                <div>
                  Avant de procéder à la pose du velux en elle-même, il est
                  indispensable de choisir le type d’ouverture. Celui-ci dépend
                  en premier lieu de votre désir. Toutefois, nos artisans vous
                  conseillent sur le style qui convient le plus avec votre
                  toiture et celle de votre voisinage. En effet, une harmonie
                  donne plus de charme à votre maison. Vous aurez notamment le
                  choix entre l’ouverture doté d’un système de rotation,
                  l’ouverture latérale ou l’ouverture à projection. Ensuite, il
                  est nécessaire de considérer la pente de la couverture de
                  toit.
                </div>
                <div>
                  Il est également important de choisir le bon matériau. Nos
                  experts, vous accompagnent encore une fois dans votre choix.
                  Vous aurez le choix entre les fenêtres en bois, les velux en
                  PVC ou les fenêtres en zinc. Il faut savoir que le zinc est un
                  excellent élément pour l’étanchéité, tandis que la fenêtre en
                  bois et en PVC offrent une excellente isolation thermique et
                  sonore. Il faut cependant comprendre que le bois est un
                  matériau qui nécessite un entretien régulier étant donné qu’il
                  est très sensible aux changements de climat. Avec ces
                  conseils, vous profiterez d’une isolation de qualité
                  inégalable.
                </div>
                <div>
                  Une fois que cela est fait, les artisans évalueront la taille
                  idéale du velux. Ils vont donc se baser sur la surface de
                  comble à aménager. L’installation du velux requiert sa
                  consolidation et l’évaluation de sa position, de son isolation
                  et de son étanchéité.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
