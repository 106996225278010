import "./styles.scss";

export default function TitleBar() {
  return (
    <h3 className="title_bar__title">
      <div className="title_bar__title_bar" />
      Sauser Couverture
    </h3>
  );
}
