import { Col, Image, Row } from "antd";
import { useEffect } from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
import {
  ContactForm,
  OurServices,
  TextDescription,
  TitleBarPrimary,
} from "../../components";
import "./styles.scss";

export default function CouvreurMarines() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const texts = [
    "On doit la qualité et la durabilité d’une maison à son isolation et étanchéité. Ces deux points sont, de ce fait, indispensables lors de la construction ou de la rénovation d’une couverture de toit. Dans l’optique de privilégier la qualité du toit, il est impératif de faire appel à un expert dans le bâtiment. Ici, il s’agit d’un couvreur. Un couvreur est un technicien qui œuvre principalement dans la réalisation de la majorité des travaux de toiture. C’est d’ailleurs l’une des spécialités de notre entreprise. Nous disposons de couvreurs expérimentés et passionnés par leur travail. Ils mettent effectivement tout en œuvre pour satisfaire la clientèle et répondre à chaque question posée.",
    "Nos artisans couvreurs de l’entreprise Sauser Couverture situé à Chantilly dans l’Oise (60), se déplacent en Picardie pour tous travaux de couverture, charpente, ravalement de façade, fuite et étanchéité de toiture, nettoyage et démoussage de toiture, zinguerie, maçonnerie, pose de velux, remplacement des tuiles en urgence suite à un dégât dans les villes de : Creil, Compiègne, Beauvais, Senlis, Noyon, Chauny, ainsi que dans l'Oise (60)', sur l'Aisne (02) et la Somme (80).",
  ];
  return (
    <div>
      <Fade>
        <div className="couvreur_marine__image"></div>
        <div className="couvreur__container">
          <ContactForm />
          <div>
            <TextDescription
              title="Couvreur à marines dans l'Oise (60) et en Picardie"
              texts={texts}
            />
            <Image src={require("../../images/background/couvreur2-1.jpeg")} />
          </div>
        </div>
        <div className="home_carousel_container">
          <OurServices />
        </div>
        <div className="home_container">
          <Row gutter={20}>
            <Col sm={12} xs={24}>
              <div className="couvreur__second_image_container">
                <div className="couvreur__second_image_content">
                  <img
                    className="couvreur__second_image"
                    src={require("../../images/img/couvreur2-2.jpeg")}
                    alt="Sauser Couverture"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <TitleBarPrimary />
              <h2 className="text_description__main_title">
                En Quoi Consiste Le Travail D’un Couvreur?
              </h2>
              <div className="text_description__text_content">
                <div>
                  Un artisan couvreur est un technicien qui maîtrise tous les
                  aspects de la toiture. Ils sont notamment charge de la pose
                  des toits ainsi que de leur réparation et rénovation. De plus,
                  nos experts vous donnent des conseils sur le design et style à
                  adopter pour votre toit, afin que votre demeure puisse
                  s’harmoniser avec le voisinage et respecter les diverses
                  restrictions liées à votre région. Ils sont donc responsables
                  de :
                </div>
                <ul>
                  <li>
                    poser correctement la couverture de toit dans les délais
                    impartis. Nos couvreurs vous conseillent également sur les
                    matériaux à choisir.
                  </li>
                  <li>
                    procéder à l’entretien régulier du toit en réalisant un
                    nettoyage, un bardage, et un démoussage.
                  </li>
                  <li>
                    installer les matériaux pour l’isolation thermique de la
                    maison. Nos artisans peuvent poser un velux dans le cas où
                    vous en avez besoin. De plus, ils vous accompagnent sur le
                    choix de matériau de ce dernier.
                  </li>
                  <li>
                    réparer les toitures détériorées pour de nombreuses raisons.
                    Avant de procéder à cette activité, ils vont déterminer s’il
                    y a des fuites au niveau du toit et de la façade. Ils
                    pourront ainsi connaître la situation et la résistance de
                    l’ensemble de la maison.
                  </li>
                  <li>
                    prendre soin de la charpente et poser les gouttières afin
                    d’éviter les accumulations d’eau au niveau de la toiture.
                  </li>
                </ul>
                <div>
                  Il faut dire que l’humidité est la pire ennemie d’une
                  couverture de toit. Il est donc important d’en prendre soin.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
}
